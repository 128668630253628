// =============================================================================
// :: Button
// =============================================================================
.a-button {
	position: relative;

	display: inline-block;
	padding: 1rem 2rem;

	text-decoration: none;
	text-align: center;
	font-family: $font-family-primary;
	font-size: $font-size-1x-large;
	font-weight: $font-weight-black;

	color: $text-color-white;
	background-color: $background-color-primary;
	border-top-right-radius: $border-radius-base;
	border-bottom-left-radius: $border-radius-base;

	transition: background-color 0.3s ease-in-out, border-radius 0.3s ease-in-out, color 0.3s ease-in-out;

	cursor: pointer;

	&:hover {
		background-color: $background-color-base;
		color: $text-color-primary;

		.a-button__text {
			color: $text-color-primary;
		}
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.a-button__icon {

	&::before {
		position: relative;
		top: -0.1rem;

		margin-right: 1rem;

		font-size: 1.1rem;
	}
}

.a-button__icon--right {

	&::before {
		margin-right: 0;
		margin-left: 1rem;
	}
}

.a-button__text {
	display: inline-block;

	font-family: $font-family-primary;
	font-size: $font-size-1x-large;
	font-weight: $font-weight-black;

	color: $text-color-white;
}

// =============================================================================
// :: Elements
// =============================================================================
.a-button--inverse {
	background-color: $background-color-base;

	.a-button__text {
		color: $text-color-primary;
	}

	.a-button__icon {

		&::before {
			color: $text-color-primary;
		}
	}

	&:hover {
		background-color: $background-color-primary;
		color: $text-color-white;

		.a-button__text {
			color: $text-color-white;
		}

		.a-button__icon {

			&::before {
				color: $text-color-white;
			}
		}
	}
}

.a-button--outline {
	background-color: $background-color-base;
	border: 0.1rem solid $border-color-blue;
	border-top-right-radius: $border-radius-base;
	border-bottom-left-radius: $border-radius-base;

	.a-button__text {
		color: $text-color-blue;
	}

	.a-button__icon {

		&::before {
			color: $text-color-blue;
		}
	}

	&:hover {
		border-color: $border-color-primary;

		.a-button__icon {

			&::before {
				color: $text-color-primary;
			}
		}
	}
}

.a-button--tiny {
	padding: 0.5rem 1.25rem;

	font-size: 1rem;

	.a-button__text {
		font-size: 1.3rem;
	}
}

// =============================================================================
// :: States
// =============================================================================
.a-button {

	&[disabled],
	&.is-disabled {
		pointer-events: none;
		cursor: not-allowed;
	}
}
