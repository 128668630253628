// =============================================================================
// :: Typography
// =============================================================================

// =============================================================================
// :: Font families
// =============================================================================
@include get-font("icons", "/assets/fonts/icons/icons");

// Kelson
@include get-font("Kelson", "/assets/fonts/kelson-sans/KelsonSans-Bold", 700, normal);
@include get-font("Kelson", "/assets/fonts/kelson-sans/KelsonSans-Regular", 500, normal);

// Overpass
@include get-font("Overpass", "/assets/fonts/overpass/Overpass-ExtraBold", 800, normal);
@include get-font("Overpass", "/assets/fonts/overpass/Overpass-Light", 300, normal);

// set
$font-family-primary: ("Overpass", "Arial", sans-serif);
$font-family-secondary: ("Kelson", "Arial", sans-serif);
$font-family-icons: ("icons", sans-serif);

// =============================================================================
// :: Font weights
// =============================================================================
/**
 * Font weight based on:
 * https://bigelowandholmes.typepad.com/bigelow-holmes/2015/07/
 */
$font-weight-extrablack: 900;
$font-weight-black: 800;
$font-weight-ultrabold: 700;
$font-weight-bold: 600;
$font-weight-dark: 500;
$font-weight-normal: 400;
$font-weight-lite: 300;
$font-weight-thin: 200;
$font-weight-ultrathin: 100;

// =============================================================================
// :: Font sizes
// =============================================================================
$font-size-5x-large: 4rem;
$font-size-4x-large: 3.8rem;
$font-size-3x-large: 2.8rem;
$font-size-2x-large: 2.4rem;
$font-size-1x-large: 1.8rem;
$font-size-base: 1.8rem;
$font-size-1x-small: 1.5rem;
$font-size-2x-small: 1.4rem;

// =============================================================================
// :: Line heights
// =============================================================================
$line-height-4x-large: 5.4rem;
$line-height-3x-large: 4.8rem;
$line-height-2x-large: 4rem;
$line-height-1x-large: 3.2rem;
$line-height-base: 2.6rem;
$line-height-1x-small: 2.3rem;
