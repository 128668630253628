// =============================================================================
// :: Card
// =============================================================================
.m-article {
	display: block;
	width: $carousel-item-width-mobile;
	height: 100%;
	margin: 0 auto;

	background-color: $background-color-base;

	transition:
		background-color 0.3s ease-in-out,
		opacity 0.3s ease-in-out;

	@include respond-at($breakpoint-mobile-base) {
		width: $carousel-item-width-desktop;
	}

	@include respond-at($breakpoint-tablet-base) {
		width: $carousel-item-width-desktop;
	}

	@include respond-at($breakpoint-desktop-large) {
		width: $carousel-item-width-tablet;
	}

	@include respond-at($breakpoint-desktop-xlarge) {
		width: $carousel-item-width-desktop;
	}

	@include respond-at($breakpoint-desktop-xxl) {
		width: $carousel-item-width-desktop;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.m-article__image {
	height: 30rem;
	margin: 0 auto;

	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.m-article__content {
	position: relative;

	padding: 5rem 4rem;
}

.m-article__title {
	font-size: 2.3rem;
	font-weight: 700;

	color: $primary-color;
}

.m-article__description {
	margin-top: 1rem;

	font-family: $font-family-primary;
	font-size: $font-size-1x-large;
	line-height: $line-height-base;

	color: $text-color-base;
}

.m-article__button {
	margin-top: 3rem;
}

.m-article__tag {
	position: absolute;
	top: 0;

	padding: 0.5rem 1.25rem;

	font-weight: 700;

	background-color: $background-color-yellow-light;
	border-radius: 1rem;

	transform: translateY(-50%);
}
