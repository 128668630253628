// =============================================================================
// :: Carousel
// =============================================================================
.s-glide-carousel {

	.glide__slide {
		height: auto;
	}

	.glide__slide--active {
		opacity: 1;

		.m-article {
			box-shadow: $box-shadow-small;
		}

		.m-card__description,
		.m-card__amount {
			color: $primary-color;
		}

		.m-card__image svg {
			fill: $primary-color;
		}
	}

	.glide__bullet--active {
		opacity: 1;
	}
}
