// =============================================================================
// :: Layout
// =============================================================================
html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

// =============================================================================
// :: Body
// =============================================================================
input,
[type="button"],
[type="reset"],
[type="submit"],
button {
	appearance: none;
	border: none;
	border-radius: 0;
}
