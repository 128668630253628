// =============================================================================
// :: Container
// =============================================================================
.l-container {
	width: 100%;
	max-width: calc(100% - #{$viewport-spacing-mobile-base * 2});
	margin: 0 auto;

	@include respond-at($breakpoint-mobile-base) {
		max-width: calc(100% - #{$viewport-spacing-mobile-base * 2});
	}

	@include respond-at($breakpoint-tablet-base) {
		max-width: calc(100% - #{$viewport-spacing-tablet-base * 2});
	}

	@include respond-at($breakpoint-desktop-base) {
		max-width: calc(100% - #{$viewport-spacing-desktop-base * 2});
	}

	@include respond-at($breakpoint-desktop-large) {
		max-width: calc(100% - #{$viewport-spacing-desktop-large * 2});
	}

	@include respond-at($container-width-base-breakpoint) {
		max-width: $container-width-base;
	}
}

// =============================================================================
// :: Modifiers
// =============================================================================
.l-container--large {

	@include respond-at($container-width-base-breakpoint) {
		max-width: calc(100% - #{$viewport-spacing-desktop-large * 2});
	}

	@include respond-at($container-width-large-breakpoint) {
		max-width: $container-width-large;
	}
}

.l-container--small {

	@include respond-at($container-width-small-breakpoint) {
		max-width: $container-width-small;
	}
}

.l-container--medium {

	@include respond-at($container-width-medium-breakpoint) {
		max-width: $container-width-medium;
	}
}

.l-container--carousel {
	max-width: 100%;

	@include respond-at($breakpoint-desktop-large) {
		max-width: 100%;
	}

	@include respond-at($breakpoint-desktop-xxl) {
		max-width: calc(#{$breakpoint-desktop-xxl} - #{$viewport-spacing-desktop-large * 2});
	}
}

.l-container--mobile {

	@include respond-at($breakpoint-mobile-base) {
		max-width: 100%;
	}
}

// =============================================================================
// :: States
// =============================================================================
