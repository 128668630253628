// =============================================================================
// :: Footer
// =============================================================================
.o-footer {
	position: relative;

	width: 100%;
	padding: 3.5rem 0;

	.l-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&::before {
		content: "";

		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 0.7rem;

		background-image: $gradient-light;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.o-footer__nav-list {
	display: flex;
	flex-direction: column;
	padding: 0;

	list-style-type: none;

	@include respond-at($breakpoint-tablet-base) {
		flex-direction: row;
	}
}

.o-footer__nav-item {

	&:not(:last-child) {
		margin-bottom: 0.5rem;

		@include respond-at($breakpoint-tablet-base) {
			margin-right: 2rem;
			margin-bottom: 0;
		}
	}
}

.o-footer__info {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	@include respond-at($breakpoint-mobile-base) {
		flex-direction: row;
		align-items: center;
	}
}

.o-footer__info-link {
	margin-left: 0.3rem;
}
