/* stylelint-disable declaration-no-important */

// =============================================================================
// :: Visibility
// =============================================================================
.u-hide {
	display: none !important;
}

.u-mobile-hide {

	@include respond-to($breakpoint-mobile-base) {
		display: none !important;
	}
}

.u-mobile-show {

	@include respond-at($breakpoint-mobile-base) {
		display: none !important;
	}
}

.u-tablet-hide {

	@include respond-to($breakpoint-tablet-base) {
		display: none !important;
	}
}

.u-tablet-show {

	@include respond-at($breakpoint-tablet-base) {
		display: none !important;
	}
}

.u-desktop-hide {

	@include respond-to($breakpoint-desktop-base) {
		display: none !important;
	}
}

.u-desktop-show {

	@include respond-at($breakpoint-desktop-base) {
		display: none !important;
	}
}
