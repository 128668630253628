// =============================================================================
// :: Settings
// =============================================================================
$arrow-width: 5.6rem;

// =============================================================================
// :: Carousel
// =============================================================================
.m-carousel {
	position: relative;

	overflow: hidden;
}

// =============================================================================
// :: Elements
// =============================================================================
.m-carousel__container {
	position: relative;
}

.m-carousel__active,
.m-carousel__arrows {
	position: absolute;
	top: 0;
	left: 50%;

	width: $carousel-item-width-mobile;
	height: 100%;

	transform: translateX(-50%);

	@include respond-at($breakpoint-mobile-base) {
		width: $carousel-item-width-desktop;
	}

	@include respond-at($breakpoint-tablet-base) {
		width: $carousel-item-width-desktop;
	}

	@include respond-at($breakpoint-desktop-large) {
		width: $carousel-item-width-tablet;
	}

	@include respond-at($breakpoint-desktop-xlarge) {
		width: $carousel-item-width-desktop;
	}

	@include respond-at($breakpoint-desktop-xxl) {
		width: $carousel-item-width-desktop;
	}
}

.m-carousel__active {
	z-index: $z-index-floor - 1;

	border-radius: 0 $border-radius-base $border-radius-base;
}

.m-carousel__slides {
	z-index: $z-index-floor;
}

.m-carousel__slide {
	opacity: 0.5;

	transition: opacity 0.3s ease-in-out;

	.m-card__description,
	.m-card__amount {
		color: $text-color-white;

		transition: color 0.3s ease-in-out;
	}

	.m-card__image svg {
		fill: $text-color-white;

		transition: fill 0.3s ease-in-out;
	}
}

.m-carousel__arrows {
	position: absolute;
	z-index: $z-index-floor + 1;

	pointer-events: none;
}

.m-carousel__arrow {
	position: absolute;
	top: 50%;

	width: $arrow-width;
	height: $arrow-width;

	border-radius: 50%;
	background-color: $background-color-base;
	box-shadow: $box-shadow-small;

	transform: translateY(-50%);

	cursor: pointer;
	pointer-events: auto;

	// Style the icon
	&::before {
		font-size: 2rem;

		color: $text-color-black;
	}
}

.m-carousel__bullets {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4rem;
}

.m-carousel__bullet {
	display: block;
	width: 1.5rem;
	height: 1.5rem;
	margin: 0 1.5rem;

	border-radius: 50%;
	opacity: 0.2;

	transition: opacity 0.3s ease-in-out;
}

// =============================================================================
// :: Modifiers
// =============================================================================
.m-carousel__arrow--left {
	@extend %__icon-arrow-left;

	left: -$arrow-width * 0.5;
}

.m-carousel__arrow--right {
	@extend %__icon-arrow-right;

	right: -$arrow-width * 0.5;
}

.m-carousel--no-active {

	.m-carousel__active {
		display: none;
	}
}

.m-carousel--icons {

	.m-carousel__active {
		background-color: $background-color-base;
		box-shadow: $box-shadow-small;
	}
}
