// =============================================================================
// :: Highlighted Info
// =============================================================================
.m-highlighted-info {
	width: 100%;
	max-width: 33rem;
}

// =============================================================================
// :: Elements
// =============================================================================
.m-highlighted-info__title {
	font-weight: $font-weight-ultrabold;
}

.m-highlighted-info__content {

	& * {
		color: $text-color-black;
	}
}
