// =============================================================================
// :: Settings
// =============================================================================
// By making the grid spacing a variable, you can easily adjust the spacing
// between the partner components, without messing up the width of the
// container.
$grid-spacing-mobile: 0.8rem;
$grid-spacing-base: 1.6rem;

// =============================================================================
// :: Partners
// =============================================================================
// .m-partners {}

// =============================================================================
// :: Elements
// =============================================================================
.m-partners__container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: calc(100% + (#{$grid-spacing-mobile} * 2));
	margin-left: calc(#{$grid-spacing-mobile} * -1);

	@include respond-at($breakpoint-mobile-base) {
		width: calc(100% + (#{$grid-spacing-base} * 2));
		margin-left: calc(#{$grid-spacing-base} * -1);
	}

	a {
		text-decoration: none;

		outline: none;
	}
}

.m-partners__item {
	margin: $grid-spacing-mobile;

	@include respond-at($breakpoint-mobile-base) {
		margin: $grid-spacing-base;
	}
}
