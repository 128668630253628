/* stylelint-disable  selector-max-class */
/* stylelint-disable  selector-max-combinators */
/* stylelint-disable  selector-max-specificity */

// ============================================================================
// :: Checkbox
// ============================================================================
.a-checkbox {
	position: relative;

	display: flex;
}

// ============================================================================
// :: Elements - element
// ============================================================================
.a-checkbox__input {

	// IE 10 and 11
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		display: none;
	}

	&[type="checkbox"]:checked,
	&[type="checkbox"]:not(:checked) {
		appearance: none;
	}

	&[type="checkbox"]:checked + .a-checkbox__label,
	&[type="checkbox"]:not(:checked) + .a-checkbox__label,
	&[type="checkbox"]:checked + .a-checkbox__label-container,
	&[type="checkbox"]:not(:checked) + .a-checkbox__label-container {
		position: relative;

		padding-left: 4rem;
	}

	&[type="checkbox"]:checked + .a-checkbox__label::before,
	&[type="checkbox"]:not(:checked) + .a-checkbox__label::before,
	&[type="checkbox"]:checked + .a-checkbox__label-container::before,
	&[type="checkbox"]:not(:checked) + .a-checkbox__label-container::before {
		content: " ";

		position: absolute;
		top: 0;
		left: 0;

		width: 2.4rem;
		height: 2.4rem;

		background: $background-color-base;
		border: $border-base;
		border-radius: 0 $border-radius-small $border-radius-small;
	}

	&[type="checkbox"] + .a-checkbox__label:hover::before,
	&[type="checkbox"] + .a-checkbox__label:hover::after,
	&[type="checkbox"] + .a-checkbox__label-container:hover::before,
	&[type="checkbox"] + .a-checkbox__label-container:hover::after {
		cursor: pointer;
	}

	&[type="checkbox"]:checked + .a-checkbox__label::after,
	&[type="checkbox"]:not(:checked) + .a-checkbox__label::after,
	&[type="checkbox"]:checked + .a-checkbox__label-container::after,
	&[type="checkbox"]:not(:checked) + .a-checkbox__label-container::after {
		content: " ";

		position: absolute;
		top: calc((2rem - 1.4rem) * 0.5);
		left: calc((2rem - 0.2rem) * 0.5);

		width: 0.7rem;
		height: 1.4rem;

		border-bottom: 0.1rem solid $border-color-dark;
		border-right: 0.1rem solid $border-color-dark;

		transform: rotate(45deg);
		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, border 0.3s ease-in-out;
	}

	&[type="checkbox"]:not(:checked) + .a-checkbox__label::after,
	&[type="checkbox"]:not(:checked) + .a-checkbox__label-container::after {
		opacity: 0;

		transform: rotate(45deg);
	}

	&[type="checkbox"]:not(:checked):hover + .a-checkbox__label::after,
	&[type="checkbox"]:not(:checked):hover + .a-checkbox__label-container::after {
		opacity: 0.3;

		transform: rotate(45deg);
	}

	&[type="checkbox"]:checked + .a-checkbox__label::after,
	&[type="checkbox"]:checked + .a-checkbox__label-container::after {
		opacity: 1;

		transform: rotate(45deg);
	}
}

.a-checkbox__label {
	display: flex;

	font-size: $font-size-1x-small;

	color: $text-color-base;
}

.a-checkbox__label,
.a-checkbox__label-container {

	// Makes sure the checkbox is completaligned to the left side
	&::before,
	&::after {
		margin-top: 0.5rem;
	}
}

.a-checkbox__label-link.a-link,
.a-checkbox__label-link {
	font-size: $font-size-1x-small;
}

.a-checkbox__error {
	position: absolute;
	top: 100%;
	left: 0;

	font-size: $font-size-2x-small;
	line-height: $line-height-1x-small;

	color: $text-color-red;
	opacity: 0;

	transition: opacity 0.3s ease-in-out;
}

.a-checkbox {

	&.has-error {

		.a-checkbox__input {

			&[type="checkbox"]:checked + .a-checkbox__label::before,
			&[type="checkbox"]:not(:checked) + .a-checkbox__label::before,
			&[type="checkbox"]:checked + .a-checkbox__label-container::before,
			&[type="checkbox"]:not(:checked) + .a-checkbox__label-container::before {
				border-color: $text-color-red;
			}
		}

		.a-checkbox__error {
			opacity: 1;
		}
	}
}
