// =============================================================================
// :: Card
// =============================================================================
.m-card {
	width: $carousel-item-width-mobile;
	margin: 0 auto;
	padding: 4rem;

	text-align: center;

	transition:
		background-color 0.3s ease-in-out,
		opacity 0.3s ease-in-out;

	@include respond-at($breakpoint-mobile-base) {
		width: $carousel-item-width-desktop;
	}

	@include respond-at($breakpoint-tablet-base) {
		width: $carousel-item-width-desktop;
	}

	@include respond-at($breakpoint-desktop-large) {
		width: $carousel-item-width-tablet;
	}

	@include respond-at($breakpoint-desktop-xlarge) {
		width: $carousel-item-width-desktop;
	}

	@include respond-at($breakpoint-desktop-xxl) {
		width: $carousel-item-width-desktop;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.m-card__amount,
.m-card__description {
	color: $primary-color;
}

.m-card__amount {
	display: block;
	margin-bottom: 5rem;

	font-family: $font-family-secondary;
	font-size: $font-size-2x-large;
	line-height: $line-height-3x-large;
	font-weight: $font-weight-bold;
}

.m-card__description {
	margin-top: 5rem;

	font-family: $font-family-primary;
	font-size: $font-size-1x-large;
	line-height: $line-height-base;
}

.m-card__image {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 18rem;
	height: 14rem;
	margin: 0 auto;

	svg {
		display: inline-block;
		width: 100%;
		height: 100%;

		fill: $primary-color;
	}
}
