/* stylelint-disable  selector-max-class */
/* stylelint-disable  selector-max-specificity */

// =============================================================================
// :: Form
// =============================================================================
// .l-form {}

// =============================================================================
// :: Elements
// =============================================================================
.l-form__fieldset {
	// Disable all default fieldset styling
	margin: 0;
	padding: 0;

	border: 0;

	+ .l-form__fieldset {
		margin-top: 2.5rem;
	}
}

.l-form__subtitle {
	/**
	 * float the <legend> to remove the default browser styling
	 */
	float: left;
	width: 100%;
	margin-bottom: 2rem;

	/**
	 * if there is a <legend> used before the grid, use clear both to fix the
	 * issues with the float: left on the legend.
	 */
	+ .l-form__grid {
		clear: both;
	}
}

.l-form__grid {

	+ .l-form__grid {
		margin-top: 2rem;
	}
}

.l-form__grid-item {

	&:not(:last-child) {
		margin-bottom: 2.5rem;
	}
}

.l-form__submit {
	display: flex;
	justify-content: center;
	margin-top: 3rem;
}

// =============================================================================
// :: States
// =============================================================================
.l-form.is-sent {

	.l-form__submit {

		.a-button {
			background-color: $background-color-green;

			&:hover {
				color: $background-color-green;

				.a-button__text {
					color: $background-color-green;
				}
			}
		}
	}
}
