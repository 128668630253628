// =============================================================================
// :: Settings
// =============================================================================
// By making the grid spacing a variable, you can easily adjust the spacing
// between the partner components, without messing up the width of the
// container.
$grid-spacing-mobile: 0.8rem;
$grid-spacing-x-base: 3.2rem;
$grid-spacing-y-base: 1.6rem;

// =============================================================================
// :: Collection partners
// =============================================================================
.m-collection-partners {
	text-align: center;
}

// =============================================================================
// :: Elements
// =============================================================================
.m-collection-partners__title {
	margin-bottom: 1.5rem;
}

.m-collection-partners__items {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin: -#{$grid-spacing-mobile};

	@include respond-at($breakpoint-mobile-base) {
		margin: -#{$grid-spacing-y-base} -#{$grid-spacing-x-base};
	}
}

.m-collection-partners__item {
	/* stylelint-disable */
	width: 33.333333%;
	/* stylelint-enable */
	padding: $grid-spacing-mobile;

	@include respond-at($breakpoint-mobile-base) {
		padding: $grid-spacing-y-base $grid-spacing-x-base;
	}

	@include respond-at($breakpoint-tablet-base) {
		width: 25%;
	}

	@include respond-at($breakpoint-desktop-base) {
		/* stylelint-disable */
		width: 16.666667%;
		/* stylelint-enable */
	}
}

.m-collection-partners__intro {
	margin-top: 1.5rem;
}
