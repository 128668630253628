// ============================================================================
// :: Video
// ============================================================================
.a-video {
	position: relative;

	height: 0;
	padding-top: 2.5rem;
	padding-bottom: 54.25%;
}

// ============================================================================
// :: Elements - iframe
// ============================================================================
.a-video__iframe {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}
