// =============================================================================
// :: Values
// =============================================================================
.m-values {

	&.l-grid__col-6-12 {
		width: $grid-col-6;

		@include respond-at($breakpoint-mobile-base) {
			width: $grid-col-6;
		}

		@include respond-at($breakpoint-tablet-base) {
			width: $grid-col-6;
		}
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.m-values__list {
	padding: 0;

	list-style-type: none;
}

.m-values__list-item {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 2.5rem;
	}
}

.m-values__list-visual {
	display: flex;
	align-items: center;
	width: 5rem;
	height: 5rem;
	margin-right: 2.5rem;
}
