// =============================================================================
// :: Spacing
// =============================================================================
/**
 * These utils are used to give a specific spacing (padding or margin) to an
 * element. This file should be filled up along the project.
 * The classes are multiples of 5 and are to be avoided when used within
 * components.
 *
 * Naming the classes:
 * u-padding-top-base
 * u-padding-bottom-large
 * u-padding-left-small
 * u-padding-right-medium
 * u-margin-top-tiny
 * u-margin-bottom-micro
 * u-margin-left-mini
 * u-margin-right-huge
*/

.u-margin-top {
	margin-top: 3rem;
}

.u-margin-bottom {
	margin-bottom: 2rem;
}
