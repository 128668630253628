// =============================================================================
// :: Partner
// =============================================================================
.m-collection-partner {
	position: relative;
	z-index: $z-index-ground;

	display: flex;
	justify-content: center;
	align-items: center;

	opacity: 0.4;

	img {
		filter: grayscale(100%);
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.m-collection-partner__image {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-bottom: 44%;

	img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		object-fit: contain;
		width: 100%;
		height: 100%;
	}
}

// =============================================================================
// :: States
// =============================================================================
.m-collection-partner {

	&:hover {
		opacity: 1;

		img {
			filter: none;
		}
	}
}
