// =============================================================================
// :: Settings
// =============================================================================
// The width of every column in a 12-column grid.
$grid-col-1: 100% / 12;
$grid-col-2: 100% / 6;
$grid-col-3: 100% / 4;
$grid-col-4: 33.3%;
$grid-col-5: 100% / 12 * 5;
$grid-col-6: 49.9%;
$grid-col-7: 100% / 12 * 7;
$grid-col-8: 66.5%;
$grid-col-9: 100% / 12 * 9;
$grid-col-10: 100% / 12 * 10;
$grid-col-11: 100% / 12 * 11;
$grid-col-12: 100%;

// =============================================================================
// :: Grid
// =============================================================================
.l-grid {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
}

// =============================================================================
// :: Elements - col & row
// =============================================================================
[class*="l-grid__col"] {
	align-self: auto;
	flex-grow: 1;
	flex-shrink: 1;
}

.l-grid__row {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	[class*="l-grid__col"] {
		flex-grow: 0;
		flex-shrink: 0;
	}
}

// =============================================================================
// :: Elements - col
// =============================================================================
.l-grid__col-1-12 {
	width: $grid-col-2;

	@include respond-at($breakpoint-desktop-base) {
		width: $grid-col-1;
	}
}

.l-grid__col-2-12 {
	width: $grid-col-4;

	@include respond-at($breakpoint-desktop-base) {
		width: $grid-col-2;
	}
}

.l-grid__col-3-12 {
	width: $grid-col-12;

	@include respond-at($breakpoint-tablet-base) {
		width: $grid-col-5;
	}

	@include respond-at($breakpoint-desktop-base) {
		width: $grid-col-3;
	}
}

.l-grid__col-4-12 {
	width: $grid-col-12;

	@include respond-at($breakpoint-tablet-base) {
		width: $grid-col-6;
	}

	@include respond-at($breakpoint-desktop-base) {
		width: $grid-col-4;
	}
}

.l-grid__col-5-12 {
	width: $grid-col-12;

	@include respond-at($breakpoint-tablet-base) {
		width: $grid-col-6;
	}

	@include respond-at($breakpoint-desktop-base) {
		width: $grid-col-5;
	}
}

.l-grid__col-6-12 {
	width: $grid-col-12;

	@include respond-at($breakpoint-tablet-base) {
		width: $grid-col-6;
	}
}

.l-grid__col-7-12 {
	width: $grid-col-12;

	@include respond-at($breakpoint-tablet-base) {
		width: $grid-col-6;
	}

	@include respond-at($breakpoint-desktop-base) {
		width: $grid-col-7;
	}
}

.l-grid__col-8-12 {
	width: $grid-col-12;

	@include respond-at($breakpoint-tablet-base) {
		width: $grid-col-6;
	}

	@include respond-at($breakpoint-desktop-base) {
		width: $grid-col-8;
	}
}

.l-grid__col-9-12 {
	width: $grid-col-12;

	@include respond-at($breakpoint-tablet-base) {
		width: $grid-col-7;
	}

	@include respond-at($breakpoint-desktop-base) {
		width: $grid-col-9;
	}
}

.l-grid__col-10-12 {
	width: $grid-col-8;

	@include respond-at($breakpoint-desktop-base) {
		width: $grid-col-10;
	}
}

.l-grid__col-11-12 {
	width: $grid-col-10;

	@include respond-at($breakpoint-desktop-base) {
		width: $grid-col-11;
	}
}

.l-grid__col-12-12 {
	width: $grid-col-12;
}

// =============================================================================
// :: Modifiers - with order
// =============================================================================
/**
 * The --order-first changes the order of the element
 */
.l-grid__col-6-12--order-first {
	@include respond-at($breakpoint-desktop-base) {
		order: -9999;
	}
}

// =============================================================================
// :: Modifiers - with margins
// =============================================================================
/**
 * The --with-margins modifier adds horizontal and vertical margin or spacing
 * between every column.
 */
.l-grid--with-margins {
	width: calc(100% + #{$grid-spacing-mobile-base});
	margin: calc((#{$grid-spacing-mobile-base} / -2));

	@include respond-at($breakpoint-tablet-base) {
		width: calc(100% + #{$grid-spacing-tablet-base});
		margin: calc((#{$grid-spacing-tablet-base} / -2));
	}

	@include respond-at($breakpoint-desktop-base) {
		width: calc(100% + #{$grid-spacing-desktop-base});
		margin: calc((#{$grid-spacing-desktop-base} / -2));
	}

	@include respond-at($breakpoint-desktop-large) {
		width: calc(100% + #{$grid-spacing-desktop-large});
		margin: calc((#{$grid-spacing-desktop-large} / -2));
	}

	[class*="l-grid__col"] {
		flex-grow: 0;
		flex-shrink: 0;
		margin: #{$grid-spacing-mobile-base / 2};

		@include respond-at($breakpoint-tablet-base) {
			margin: #{$grid-spacing-tablet-base / 2};
		}

		@include respond-at($breakpoint-desktop-base) {
			margin: #{$grid-spacing-desktop-base / 2};
		}

		@include respond-at($breakpoint-desktop-large) {
			margin: #{$grid-spacing-desktop-large / 2};
		}
	}

	.l-grid__col-1-12 {
		width: calc(#{$grid-col-2} - #{$grid-spacing-mobile-base});

		@include respond-at($breakpoint-tablet-base) {
			width: calc(#{$grid-col-2} - #{$grid-spacing-tablet-base});
		}

		@include respond-at($breakpoint-desktop-base) {
			width: calc(#{$grid-col-1} - #{$grid-spacing-desktop-base});
		}

		@include respond-at($breakpoint-desktop-large) {
			width: calc(#{$grid-col-1} - #{$grid-spacing-desktop-large});
		}
	}

	.l-grid__col-2-12 {
		width: calc(#{$grid-col-4} - #{$grid-spacing-mobile-base});

		@include respond-at($breakpoint-tablet-base) {
			width: calc(#{$grid-col-4} - #{$grid-spacing-tablet-base});
		}

		@include respond-at($breakpoint-desktop-base) {
			width: calc(#{$grid-col-2} - #{$grid-spacing-desktop-base});
		}

		@include respond-at($breakpoint-desktop-large) {
			width: calc(#{$grid-col-2} - #{$grid-spacing-desktop-large});
		}
	}

	.l-grid__col-3-12 {
		width: calc(#{$grid-col-12} - #{$grid-spacing-mobile-base});

		@include respond-at($breakpoint-tablet-base) {
			width: calc(#{$grid-col-5} - #{$grid-spacing-tablet-base});
		}

		@include respond-at($breakpoint-desktop-base) {
			width: calc(#{$grid-col-3} - #{$grid-spacing-desktop-base});
		}

		@include respond-at($breakpoint-desktop-large) {
			width: calc(#{$grid-col-3} - #{$grid-spacing-desktop-large});
		}
	}

	.l-grid__col-4-12 {
		width: calc(#{$grid-col-12} - #{$grid-spacing-mobile-base});

		@include respond-at($breakpoint-tablet-base) {
			width: calc(#{$grid-col-6} - #{$grid-spacing-tablet-base});
		}

		@include respond-at($breakpoint-desktop-base) {
			width: calc(#{$grid-col-4} - #{$grid-spacing-desktop-base});
		}

		@include respond-at($breakpoint-desktop-large) {
			width: calc(#{$grid-col-4} - #{$grid-spacing-desktop-large});
		}
	}

	.l-grid__col-5-12 {
		width: calc(#{$grid-col-12} - #{$grid-spacing-mobile-base});

		@include respond-at($breakpoint-tablet-base) {
			width: calc(#{$grid-col-5} - #{$grid-spacing-tablet-base});
		}

		@include respond-at($breakpoint-desktop-base) {
			width: calc(#{$grid-col-5} - #{$grid-spacing-desktop-base});
		}

		@include respond-at($breakpoint-desktop-large) {
			width: calc(#{$grid-col-5} - #{$grid-spacing-desktop-large});
		}
	}

	.l-grid__col-6-12 {
		width: calc(#{$grid-col-12} - #{$grid-spacing-mobile-base});

		@include respond-at($breakpoint-tablet-base) {
			width: calc(#{$grid-col-12} - #{$grid-spacing-tablet-base});
		}

		@include respond-at($breakpoint-desktop-base) {
			width: calc(#{$grid-col-6} - #{$grid-spacing-desktop-base});
		}

		@include respond-at($breakpoint-desktop-large) {
			width: calc(#{$grid-col-6} - #{$grid-spacing-desktop-large});
		}
	}

	.l-grid__col-7-12 {
		width: calc(#{$grid-col-12} - #{$grid-spacing-mobile-base});

		@include respond-at($breakpoint-tablet-base) {
			width: calc(#{$grid-col-7} - #{$grid-spacing-tablet-base});
		}

		@include respond-at($breakpoint-desktop-base) {
			width: calc(#{$grid-col-7} - #{$grid-spacing-desktop-base});
		}

		@include respond-at($breakpoint-desktop-large) {
			width: calc(#{$grid-col-7} - #{$grid-spacing-desktop-large});
		}
	}

	.l-grid__col-8-12 {
		width: calc(#{$grid-col-12} - #{$grid-spacing-mobile-base});

		@include respond-at($breakpoint-tablet-base) {
			width: calc(#{$grid-col-6} - #{$grid-spacing-tablet-base});
		}

		@include respond-at($breakpoint-desktop-base) {
			width: calc(#{$grid-col-8} - #{$grid-spacing-desktop-base});
		}

		@include respond-at($breakpoint-desktop-large) {
			width: calc(#{$grid-col-8} - #{$grid-spacing-desktop-large});
		}
	}

	.l-grid__col-9-12 {
		width: calc(#{$grid-col-12} - #{$grid-spacing-mobile-base});

		@include respond-at($breakpoint-tablet-base) {
			width: calc(#{$grid-col-7} - #{$grid-spacing-tablet-base});
		}

		@include respond-at($breakpoint-desktop-base) {
			width: calc(#{$grid-col-9} - #{$grid-spacing-desktop-base});
		}

		@include respond-at($breakpoint-desktop-large) {
			width: calc(#{$grid-col-9} - #{$grid-spacing-desktop-large});
		}
	}

	.l-grid__col-10-12 {
		width: calc(#{$grid-col-8} - #{$grid-spacing-mobile-base});

		@include respond-at($breakpoint-tablet-base) {
			width: calc(#{$grid-col-8} - #{$grid-spacing-tablet-base});
		}

		@include respond-at($breakpoint-desktop-base) {
			width: calc(#{$grid-col-10} - #{$grid-spacing-desktop-base});
		}

		@include respond-at($breakpoint-desktop-large) {
			width: calc(#{$grid-col-10} - #{$grid-spacing-desktop-large});
		}
	}

	.l-grid__col-11-12 {
		width: calc(#{$grid-col-10} - #{$grid-spacing-mobile-base});

		@include respond-at($breakpoint-tablet-base) {
			width: calc(#{$grid-col-10} - #{$grid-spacing-tablet-base});
		}

		@include respond-at($breakpoint-desktop-base) {
			width: calc(#{$grid-col-10} - #{$grid-spacing-desktop-base});
		}

		@include respond-at($breakpoint-desktop-large) {
			width: calc(#{$grid-col-11} - #{$grid-spacing-desktop-large});
		}
	}

	.l-grid__col-12-12 {
		width: calc(#{$grid-col-12} - #{$grid-spacing-mobile-base});

		@include respond-at($breakpoint-tablet-base) {
			width: calc(#{$grid-col-12} - #{$grid-spacing-tablet-base});
		}

		@include respond-at($breakpoint-desktop-base) {
			width: calc(#{$grid-col-12} - #{$grid-spacing-desktop-base});
		}

		@include respond-at($breakpoint-desktop-large) {
			width: calc(#{$grid-col-12} - #{$grid-spacing-desktop-large});
		}
	}
}
