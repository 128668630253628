// =============================================================================
// :: Hero
// =============================================================================
.o-hero {
	position: relative;

	padding-bottom: 3rem;

	background-color: $background-color-grey-1x-light;

	pointer-events: all;

	@include respond-at($breakpoint-tablet-base) {
		padding-bottom: 8rem;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.o-hero__header-container {
	position: relative;
	z-index: $z-index-floor;

	padding: 9rem 0;

	@include respond-at($breakpoint-mobile-base) {
		padding: 15rem 0;
	}

	@include respond-at($breakpoint-tablet-base) {
		padding: 18rem 0;
	}

	&::before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;
		z-index: $z-index-pit;

		width: 100%;
		height: 100%;

		background-image: $gradient-dark, url("/assets/img/hero-bg.jpg");
		background-blend-mode: soft-light;

		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-bottom-right-radius: $border-radius-large;

		opacity: 0.95;

		// IE 10 and 11
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			background-image: url("/assets/img/hero-bg.jpg");
		}
	}

	&::after {
		content: "";

		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;

		width: 100%;
		height: 100%;

		background-color: $background-color-dark;
		opacity: 0.2;

		border-bottom-right-radius: $border-radius-large;
	}
}

.o-hero__header {
	width: 100%;
	max-width: 42rem;
}

.o-hero__content {
	position: relative;
	z-index: $z-index-floor + 1;

	margin-top: 3rem;

	@include respond-at($breakpoint-desktop-base) {
		margin-top: -8rem;
	}
}

// =============================================================================
// :: Modifiers
// =============================================================================
.o-hero--small {
	background-color: $background-color-base;

	.o-hero__header-container {
		padding: 9rem 0;
	}
}
