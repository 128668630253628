// =============================================================================
// :: Wysiwyg
// =============================================================================
.s-wysiwyg {

	h1,
	h2,
	h3,
	h4 {
		margin-bottom: 1.5rem;

		color: $text-color-primary;
	}

	a {
		color: $text-color-primary;
	}

	ol,
	ul {
		font-family: $font-family-primary;
		font-weight: $font-weight-lite;
		font-size: $font-size-1x-small;
		line-height: $line-height-1x-small;

		color: $text-color-base;

		@include respond-at($breakpoint-mobile-base) {
			font-size: $font-size-base;
			line-height: $line-height-base;
		}
	}

	strong {
		font-weight: $font-weight-black;
	}

	blockquote {
		font-weight: $font-weight-ultrabold;

		color: $text-color-primary;

		&::before {
			content: open-quote;

			position: absolute;

			margin-left: -4.5rem;

			font-size: 5rem;
		}
	}

	table {
		width: 100%;
		margin: 2rem 0;

		border-collapse: collapse;

		td {
			padding: 1rem;
		}
	}

	table,
	th,
	td {
		border: 0.1rem solid rgba($grey-42, 0.2);
	}
}
