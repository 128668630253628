/* stylelint-disable */

// =============================================================================
// :: Icon class names
// =============================================================================
/**
 * Created with the 'gulp iconfont' gulp-task, the source template of this
 * file can be found in src/assets/_templates/icons/template.lodash
 */

// =============================================================================
// :: arrow-left
// =============================================================================
%__icon-arrow-left {

	&:before {
		@extend %___default-icon;

		content: "\EA01";
		font-family: "icons";
	}
}

.icon-arrow-left {
	@extend %__icon-arrow-left;
}

// =============================================================================
// :: arrow-right
// =============================================================================
%__icon-arrow-right {

	&:before {
		@extend %___default-icon;

		content: "\EA02";
		font-family: "icons";
	}
}

.icon-arrow-right {
	@extend %__icon-arrow-right;
}
