// =============================================================================
// :: Image
// =============================================================================
.a-image {
	position: relative;
}

// =============================================================================
// :: Elements - visual
// =============================================================================
.a-image__visual {
	width: 100%;
	max-width: 100%;
}

.a-image__link {
	transition: opacity 0.3s ease-in-out;

	&:hover {
		opacity: 0.8;
	}
}

// =============================================================================
// :: Modifiers
// =============================================================================
.a-image--shadow {
	position: relative;

	&::after {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		border-radius: 0 $border-radius-base $border-radius-base;
		box-shadow: $box-shadow-base;
	}
}
