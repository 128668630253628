// =============================================================================
// :: Icon
// =============================================================================
/**
 * This is a definition that can be used for icon inheritance. Every icon
 * generated and loaded from the iconfont has its base defined here.
 */
%___default-icon {
	vertical-align: middle;

	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;


	speak: none;

	// Improve font-rendering
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}
