// =============================================================================
// :: Values
// =============================================================================
.o-values {

	@include respond-at($breakpoint-desktop-base) {
		padding: 4.5rem 3rem;
	}

	@include respond-at($breakpoint-desktop-base) {
		background-color: $background-color-base;
		box-shadow: $box-shadow-base;
		border-bottom-right-radius: $border-radius-medium;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.o-values__list {
	padding: 0;

	list-style-type: none;

	@include respond-at($breakpoint-tablet-base) {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	@include respond-at($breakpoint-desktop-base) {
		flex-wrap: nowrap;
	}
}

.o-values__list-item {
	display: flex;
	align-items: center;
	flex-basis: 100%;

	&:not(:last-child) {

		@include respond-to($breakpoint-tablet-base) {
			margin-bottom: 2.5rem;
		}

		@include respond-at($breakpoint-desktop-base) {
			margin-right: 5rem;
			margin-bottom: 0;
		}
	}

	@include respond-at($breakpoint-tablet-base) {
		flex-direction: column;
		width: 24%;
		margin: 0 2rem 4rem;
	}

	@include respond-at($breakpoint-desktop-base) {
		width: auto;
		margin: 0 2rem;
	}
}

.o-values__list-visual {
	display: flex;
	align-items: center;
	width: 5rem;
	margin-right: 2.5rem;

	@include respond-at($breakpoint-tablet-base) {
		width: 9rem;
		height: 7rem;
		margin-right: 0;
		margin-bottom: 3rem;
	}
}

.o-values__list-text {
	width: 100%;

	@include respond-at($breakpoint-tablet-base) {
		text-align: center;
	}
}
