// =============================================================================
// :: Base
// =============================================================================
html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-size: 62.5%;
}

body {
	font-family: $font-family-primary;
	font-size: $font-size-base;
	line-height: $line-height-base;
}

// =============================================================================
// :: Headers
// =============================================================================
h1 {
	font-family: $font-family-secondary;
	font-weight: $font-weight-normal;
	font-size: $font-size-3x-large;
	line-height: $line-height-2x-large;

	@include respond-at($breakpoint-mobile-base) {
		font-size: $font-size-5x-large;
		line-height: $line-height-4x-large;
	}
}

h2 {
	font-family: $font-family-secondary;
	font-weight: $font-weight-normal;
	font-size: $font-size-2x-large;
	line-height: $line-height-1x-large;

	@include respond-at($breakpoint-mobile-base) {
		font-size: $font-size-4x-large;
		line-height: $line-height-3x-large;
	}
}

h3 {
	font-family: $font-family-primary;
	font-weight: $font-weight-normal;
	font-size: $font-size-1x-large;
	line-height: $line-height-base;

	color: $text-color-primary;
}

// =============================================================================
// :: Text
// =============================================================================
p {
	font-family: $font-family-primary;
	font-weight: $font-weight-lite;
	font-size: $font-size-1x-small;
	line-height: $line-height-1x-small;

	color: $text-color-base;

	@include respond-at($breakpoint-mobile-base) {
		font-size: $font-size-base;
		line-height: $line-height-base;
	}
}

// =============================================================================
// :: Link
// =============================================================================
a,
button {

	&:hover,
	&:active,
	&:focus {
		outline: none;
	}
}
