// =============================================================================
// :: Input
// =============================================================================
.a-input {
	position: relative;
}

// =============================================================================
// :: Elements
// =============================================================================
.a-input__label {
	display: block;
	margin-bottom: 0.5rem;

	font-size: $font-size-2x-small;
	font-weight: $font-weight-black;
	text-transform: uppercase;
}

.a-input__container {
	position: relative;
}

.a-input__field {
	width: 100%;
	padding: 0.9rem 1rem;

	border: $border-base;
	border-radius: 0 $border-radius-base $border-radius-base;

	transition: border 0.3s ease-in-out;

	&:focus {
		outline: none;
	}

	@include placeholder() {
		// Style the placeholder
	}
}

.a-input__error {
	position: absolute;

	font-size: $font-size-2x-small;
	line-height: $line-height-1x-small;

	color: $text-color-red;
	opacity: 0;

	transition: opacity 0.3s ease-in-out;
}

// =============================================================================
// :: States
// =============================================================================
.a-input {

	&.has-error {

		.a-input__field {
			border-color: $text-color-red;
		}

		.a-input__error {
			opacity: 1;
		}
	}
}
