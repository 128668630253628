// =============================================================================
// :: Link
// =============================================================================
.a-link {
	font-family: $font-family-primary;
	font-size: $font-size-base;
	font-weight: $font-weight-bold;
	text-decoration: none;
	text-align: center;

	color: $text-color-primary;
}

// =============================================================================
// :: Modifiers
// =============================================================================
.a-link--black {
	color: $text-color-black;
}

.a-link--blue {
	color: $text-color-blue;
}

.a-link--white {
	color: $text-color-white;
}

.a-link--small {
	font-weight: $font-weight-lite;
}

.a-link--bigger {
	font-size: 1.8rem;
}
