// =============================================================================
// :: Card
// =============================================================================
.m-resource {
	padding: 2rem;

	text-align: center;

	background-color: $background-color-base;
}

// =============================================================================
// :: Elements
// =============================================================================
.m-resource__title {
	margin-bottom: 2rem;
}

.m-resource__image {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 18rem;
	height: 14rem;
	margin: 0 auto;

	svg {
		display: inline-block;
		width: 100%;
		height: 100%;

		fill: $primary-color;
	}

	img {
		max-height: 13rem;
	}
}
