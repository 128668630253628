// =============================================================================
// :: Partners
// =============================================================================
.s-partners {
	position: relative;

	padding: 2rem 0 0;

	@include respond-at($breakpoint-tablet-base) {
		padding: 10rem 0;
	}

	&::after {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-image: url("/assets/img/europe.png");
		background-repeat: no-repeat;
		background-position: center;
		background-size: 180%;

		@include respond-at($breakpoint-tablet-base) {
			background-size: contain;
		}
	}
}

// =============================================================================
// :: Elements
// =============================================================================
