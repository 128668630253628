// =============================================================================
// :: Partner
// =============================================================================
.m-partner {
	position: relative;
	z-index: $z-index-ground;

	width: 13rem;
	padding: 3rem 2.5rem 2rem;

	border-radius: 0 $border-radius-base $border-radius-base;
	background-color: $background-color-base;

	@include respond-at($breakpoint-mobile-base) {
		width: 30rem;
	}

	&::after {
		content: "";

		position: absolute;
		top: 0;
		left: 0;
		z-index: $z-index-cellar;

		width: 100%;
		height: 100%;

		opacity: 1;
		box-shadow: $box-shadow-base;
		border-radius: 0 $border-radius-base $border-radius-base;

		transition: all 0.3s;
	}

	&:hover::after {
		box-shadow: $box-shadow-hover;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.m-partner__visual {
	display: flex;
	justify-content: center;
	align-items: center;
}

.m-partner__name {
	display: block;
	margin-top: 1rem;

	font-size: 1.2rem;
	font-weight: $font-weight-bold;
	text-align: center;
	text-transform: uppercase;

	color: $text-color-grey-light;

	@include respond-at($breakpoint-mobile-base) {
		font-size: 1.8rem;
	}
}

.m-partner__image {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-bottom: 44%;

	img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		object-fit: contain;
		width: 100%;
		height: 100%;
	}
}
