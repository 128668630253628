// =============================================================================
// :: Section
// =============================================================================
.l-section {
	padding: 4rem 0;

	@include respond-at($breakpoint-tablet-base) {
		padding: 8rem 0;
	}

	@include respond-at($breakpoint-desktop-base) {
		padding: 12rem 0;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.l-section__header {
	margin-bottom: 2rem;
}

.l-section__content-item {

	&:not(:last-child) {
		margin-bottom: 2.5rem;
	}
}

.l-section__container {
	width: 100%;
}

.l-section__visual {
	max-width: 100%;
}

// =============================================================================
// :: Modifiers - Layout
// =============================================================================
.l-section--split-space {

	&.l-section .l-container,
	&.l-section {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		@include respond-at($breakpoint-desktop-base) {
			flex-direction: row;
		}
	}

	.l-section__container {
		margin-right: 0;
		margin-bottom: 3rem;

		@include respond-at($breakpoint-desktop-base) {
			max-width: 50rem;
			margin-right: 12rem;
			margin-bottom: 0;
		}
	}
}

.l-section--reverse {

	.l-section__container {
		margin-right: 0;

		@include respond-at($breakpoint-desktop-base) {
			order: 2;
			margin-left: 12rem;
		}
	}
}

.l-section--spacing-top-small {

	@include respond-at($breakpoint-tablet-base) {
		padding: 7rem 0;
	}

	@include respond-at($breakpoint-desktop-base) {
		padding: 7rem 0 12rem;
	}
}

.l-section--spacing-top-extra-small {
	padding-top: 1.5rem;

	@include respond-at($breakpoint-tablet-base) {
		padding-top: 2rem;
	}

	@include respond-at($breakpoint-tablet-base) {
		padding-top: 2rem;
	}
}

.l-section--spacing-top-none {

	@include respond-at($breakpoint-tablet-base) {
		padding-top: 0;
	}

	@include respond-at($breakpoint-desktop-base) {
		padding-top: 0;
	}
}

.l-section--spacing-bottom-none {

	@include respond-at($breakpoint-tablet-base) {
		padding-bottom: 0;
	}

	@include respond-at($breakpoint-desktop-base) {
		padding-bottom: 0;
	}
}

.l-section--spacing-bottom-large {

	@include respond-at($breakpoint-tablet-base) {
		padding-bottom: 8rem;
	}

	@include respond-at($breakpoint-desktop-base) {
		padding-bottom: 12rem;
	}
}

.l-section--spacing-small {

	@include respond-at($breakpoint-tablet-base) {
		padding: 7rem 0;
	}

	@include respond-at($breakpoint-tablet-base) {
		padding: 7rem 0;
	}
}

.l-section--spacing-extra-small {
	padding: 1.5rem 0;

	@include respond-at($breakpoint-tablet-base) {
		padding: 2rem 0;
	}

	@include respond-at($breakpoint-tablet-base) {
		padding: 2rem 0;
	}
}

.l-section__header--spacing-large {
	margin-bottom: 4rem;

	@include respond-at($breakpoint-tablet-base) {
		margin-bottom: 6rem;
	}
}

.l-section--spacing-top-none {
	padding-top: 0;
}

.l-section__header--size-medium.m-header,
.l-section__header--size-medium {
	width: 100%;
	max-width: 36rem;
}

.l-section__header--size-small.m-header,
.l-section__header--size-small {
	width: 100%;
	max-width: 28rem;
}

.l-section__header--size-extra-small.m-header,
.l-section__header--size-extra-small {
	width: 100%;
	max-width: 25rem;
}

// =============================================================================
// :: Modifiers - Styling
// =============================================================================
.l-section--gradient {
	background-image: $gradient-base;
}

.l-section--inverted {

	& * {
		color: $text-color-white;
	}
}

.l-section--grey {
	background-color: $background-color-grey-1x-light;

	&::before {
		background-color: $background-color-grey-1x-light;
	}
}

.l-section--radius {
	border-bottom-right-radius: $border-radius-large;
}

.l-section--background-limit-70 {
	position: relative;

	padding-bottom: 0;

	background-color: transparent;

	&::before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;

		// Because the class is limited to 70, we limit the height to be 70%
		height: 70%;
	}
}

.l-section--border-bottom {
	border-bottom-right-radius: 15rem;
}
