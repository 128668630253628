// =============================================================================
// :: Back button
// =============================================================================
.a-back-button {
	position: absolute;
	top: -1rem;
	left: -9rem;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 6rem;
	height: 6rem;

	text-decoration: none;

	color: $text-color-black;
	background-color: $background-color-base;
	border-radius: 100%;
	box-shadow: $box-shadow-hard;

	&::before {
		content: "\EA01";

		font-size: 2.5rem;
		font-family: $font-family-icons;
	}

	@include respond-to($breakpoint-tablet-base) {
		display: none;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
