// =============================================================================
// :: Header
// =============================================================================
.o-header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: $z-index-atmosphere;

	width: 100%;
	padding: 3rem 0;

	@include respond-at($breakpoint-mobile-base) {
		padding: 4rem 0;
	}

	.l-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

// =============================================================================
// :: Elements
// =============================================================================
.o-header__logo {
	display: flex;
	align-items: center;
	width: 7rem;

	@include respond-at($breakpoint-mobile-base) {
		width: 14rem;
	}
}

.o-header__action {

	@include respond-at($breakpoint-desktop-base) {
		display: none;
	}
}

.o-header__nav-list {
	display: flex;
	align-items: center;
	padding: 0;

	list-style: none;

	li {
		margin-right: 2rem;

		@include respond-at($breakpoint-desktop-base) {
			margin-right: 2.5rem;
		}

		.a-link {

			&:hover {
				text-decoration: underline;
			}
		}
	}

	@include respond-to($breakpoint-desktop-base) {
		display: none;
	}
}
