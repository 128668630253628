// =============================================================================
// :: Header
// =============================================================================
.m-header {
	position: relative;

	width: 100%;
	max-width: 68rem;

	color: $text-color-white;
}

// =============================================================================
// :: Modifiers
// =============================================================================
.m-header--orange {
	color: $text-color-primary;
}

.m-header--underline {
	position: relative;

	&::after {
		content: "";

		position: absolute;
		bottom: -1.4rem;
		left: 0;

		width: 15rem;
		height: 0.7rem;

		background-image: $gradient-light;
	}
}

.m-header--center {
	// Not using margin: 0 auto here because it l-section__header has to be
	// able to be in charge of spacing.
	margin-right: auto;
	margin-left: auto;

	text-align: center;
}

.m-header--with-text {

	.m-header__title {
		margin-bottom: 1.5rem;
	}
}
